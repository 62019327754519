import { SortDirection, SortValue } from 'reablocks';
import { useEffect, useRef } from 'react';
import isEqual from 'react-fast-compare';
import { useUpdateEffect } from 'react-use';
import rison from 'rison';
import {
  NumberParam,
  StringParam,
  useQueryParam,
  withDefault
} from 'use-query-params';

export interface FilterPagerInput {
  defaultFilter?: any;
  defaultPageSize?: number;
  defaultSort?: SortValue;
}

export interface FilterPagerResult {
  page: number;
  setPage: (page: number) => void;
  sort: SortValue;
  setSort: (sort: SortValue) => void;
  keyword: string;
  setKeyword: (keyword: string) => void;
  filter: any;
  setFilter: (filter: any) => void;
  savedFilterId: string;
  setSavedFilterId: (savedFilterId: string) => void;
  pageSize: number;
  setPageSize: (size: number) => void;
  pageVariables: any;
}

export const useFilterPager = ({
  defaultFilter,
  defaultPageSize = 100,
  defaultSort
}: FilterPagerInput) => {
  const [page, setPage] = useQueryParam('page', withDefault(NumberParam, 0));
  const [pageSize, setPageSize] = useQueryParam(
    'pageSize',
    withDefault(NumberParam, defaultPageSize)
  );
  const [savedFilterId, setSavedFilterId] = useQueryParam(
    'savedFilterId',
    withDefault(StringParam, '')
  );

  const mounted = useRef<boolean>(false);

  const [sort, setSort] = useQueryParam<SortValue>('sort', {
    equals: isEqual,
    encode(sort?: SortValue) {
      let newSort;
      if (sort) {
        newSort = `${sort.direction === 'asc' ? '' : '-'}${sort.field}`;
      }

      return newSort;
    },
    decode(strValue?: any) {
      if (strValue) {
        const splits = strValue.split('-');
        const has = splits.length > 1;
        const direction = has ? 'desc' : 'asc';
        const field = splits.slice(has ? 1 : 0).join('');

        return {
          field,
          direction: direction as SortDirection
        };
      } else {
        return defaultSort;
      }
    }
  });

  const [keyword, setKeyword] = useQueryParam(
    'keyword',
    withDefault(StringParam, '')
  );

  const [filter, setFilter] = useQueryParam<any>('filter', {
    equals: isEqual,
    encode(value: any) {
      if (value) {
        return rison.encode(value);
      }
    },
    decode(strValue?: any) {
      if (strValue) {
        return rison.decode(strValue);
      }
    }
  });

  useEffect(() => {
    if (!filter && defaultFilter) {
      setFilter(defaultFilter);
    }
  }, [filter, defaultFilter, setFilter]);

  useUpdateEffect(() => {
    // If the user changes the sort, filter, keyword or pageSize,
    // we should reset the page to 0
    if (mounted.current) {
      setPage(0);
    } else {
      mounted.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, sort, keyword, pageSize]);

  const pageVariables = {
    pageSize,
    offset: page * pageSize || 0,
    orderBy: `${sort.direction === 'asc' ? '' : '-'}${sort.field}`,
    // TODO: why not just rename keyword => search in useFilterPager?  I think
    //       every(?) list page uses the variable "search", but if not it's easy
    //       to change.
    search: keyword
  };

  return {
    page,
    setPage,
    pageSize,
    setPageSize,
    sort,
    setSort,
    keyword,
    setKeyword,
    filter,
    setFilter,
    savedFilterId,
    setSavedFilterId,
    pageVariables // all of the pagination/sort/search variables
  } as FilterPagerResult;
};
